* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
  background-color: var(--canvas);
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}